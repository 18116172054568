<template>
	<div :class="{fullscreen:fullscreen}" class="tinymce-container" :style="{width:containerWidth}">
		<textarea :id="tinymceId" class="tinymce-textarea"/>
		<div class="editor-custom-btn-container">
			<editorImage color="#1890ff" class="editor-upload-btn" @successCBK="imageSuccessCBK"/>
		</div>
	</div>
</template>

<script>
/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */
import editorImage from './components/EditorImage'
import plugins from './plugins'
import toolbar from './toolbar'
import load from './dynamicLoadScript'
import {getQNToken} from "@/api/basic";
import $ from 'jquery'
// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
// const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js'
const tinymceCDN = 'https://unpkg.com/tinymce-all-in-one@4.9.3/tinymce.min.js'


export default {
  name: 'Tinymce',
  components: { editorImage },
  props: {
    id: {
      type: String,
      default: function () {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    value: {
      type: String,
      default: ''
    },
    toolbar: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table'
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto'
    }
  },
  data () {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        'en': 'en',
        'zh': 'zh_CN',
        'es': 'es_MX',
        'ja': 'ja'
      },
			imageUrl: '',
			imageName: '',
    }
  },
  computed: {
    containerWidth () {
      const width = this.width
      if (/^[\d]+(\.[\d]+)?$/.test(width)) { // matches `100`, `'100'`
        return `${width}px`
      }
      return width
    }
  },
  watch: {
    value (val) {
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || ''))
      }
    }
  },
  mounted () {
    this.init()
  },
  activated () {
    if (window.tinymce) {
      this.initTinymce()
    }
  },
  deactivated () {
    this.destroyTinymce()
  },
  destroyed () {
    this.destroyTinymce()
  },
  methods: {
    init () {
      // dynamic load tinymce from cdn
      load(tinymceCDN, (err) => {
        if (err) {
          this.$message.error(err.message)
          return
        }
        this.initTinymce()
      })
    },
    initTinymce () {
      const _this = this
      window.tinymce.init({
        // images_upload_url: _this.imageUrl,
        // images_upload_base_path: ' https://pic.hngegejk.cn/',
        selector: `#${this.tinymceId}`,
        language: this.languageTypeList['zh'],
        height: this.height,
        body_class: 'panel-body ',
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: this.menubar,
        plugins: plugins,
        end_container_on_empty_block: true,
        powerpaste_word_import: 'clean',
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
        default_link_target: '_blank',
        link_title: false,
        nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        init_instance_callback: editor => {
          if (_this.value) {
            editor.setContent(_this.value)
          }
          _this.hasInit = true
          editor.on('NodeChange Change KeyUp SetContent', () => {
            this.hasChange = true
            this.$emit('input', editor.getContent())
          })
        },
        setup (editor) {
          editor.on('FullscreenStateChanged', (e) => {
            _this.fullscreen = e.state
          })
        },
        // it will try to keep these URLs intact
        // https://www.tiny.cloud/docs-3x/reference/configuration/Configuration3x@convert_urls/
        // https://stackoverflow.com/questions/5196205/disable-tinymce-absolute-to-relative-url-conversions
        convert_urls: false,
        // file_picker_callback: function(callback, value, meta) {
        //   // Provide file and text for the link dialog
        //   if (meta.filetype == 'file') {
        //     callback('mypage.html', {text: 'My text'});
        //   }
        //   // Provide image and alt text for the image dialog
        //   if (meta.filetype == 'image') {
        //     callback(_this.imageUrl, {alt: _this.imageName});
        //   }
        //   // Provide alternative source and posted for the media dialog
        //   if (meta.filetype == 'media') {
        //     callback('movie.mp4', {source2: 'alt.ogg', poster: 'image.jpg'});
        //   }
        // },
        images_upload_handler (blobInfo, success, failure, progress) {
          progress(0)
          let key =  Math.random().toString(36).substr(2, 34)
          getQNToken().then(response => {
            let data = response
						_this.imageName = blobInfo.blob().name
            if (data.code == 200) {
              console.log(blobInfo.blob())
              const url = 'https://upload.qiniup.com';
              const formData = new FormData();
              formData.append('token', response.data.token);
              formData.append('key', key);
              formData.append('file', blobInfo.blob(), url);
              $.ajax(
                {
                  type: 'POST',
                  url: url,
                  data: formData,
                  processData: false,
                  contentType: false,
                  success: function(data) {
                    console.log(data)
                    _this.imageUrl = data.key
                    console.log(_this.imageUrl)
                    _this.$message({
                      message: '图片上传成功',
                      type: 'success'
										})
										success('https://pic.hngegejk.cn/' + key)
                  },
                  error: function(e) {
                    alert("图片上传失败");
                  }
                }
              )
            }
          }).catch(error => {
            console.log(error);
          }).finally(
          
					)
        
        },

      })
    },
    destroyTinymce () {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }

      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent (value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent () {
      window.tinymce.get(this.tinymceId).getContent()
    },
    imageSuccessCBK (arr) {
      arr.forEach(v => window.tinymce.get(this.tinymceId).insertContent(`<img class="wscnph" src="${v.url}" >`))
    }
  }
}
</script>

<style lang="scss" scoped>
	.tinymce-container {
		position: relative;
		line-height: normal;
	}
	
	.tinymce-container {
		::v-deep {
			.mce-fullscreen {
				z-index: 10000;
			}
		}
	}
	
	.tinymce-textarea {
		visibility: hidden;
		z-index: -1;
	}
	
	.editor-custom-btn-container {
		position: absolute;
		right: 4px;
		top: 4px;
		/*z-index: 2005;*/
	}
	
	.fullscreen .editor-custom-btn-container {
		z-index: 10000;
		position: fixed;
	}
	
	.editor-upload-btn {
		display: inline-block;
	}
</style>
